//==============================================================================
// Internal:
import {
  orderedAgentList,
  sortByAgencyName,
  sortByLastName,
  sortByPriority,
  sortByRating
} from "../../utils/utils";
//==============================================================================

export const handleList = ({ agentsObj, filter, searchCriteria, searchTerm, sort }) => {
  let agentsList;

  if (Object.keys(agentsObj).length < 1) return;

  if (filter === 'doNotQuery') {
    const agentsList = Object.values(agentsObj);
    return onFilter(agentsList, filter);
  }

  agentsList = orderedAgentList(agentsObj);
  agentsList = onFilter(agentsList, filter);
  agentsList = onSort(agentsList, sort);
  agentsList = onSearch(agentsList, searchCriteria, searchTerm)
  return agentsList
}

export const onSearch = (agentsList, searchCriteria, searchTerm) => {
  if (!searchTerm) {
    return agentsList;
  }

  switch (searchCriteria) {
    case 'none': {
      return agentsList;
    }
    case 'lastName': {
      return agentsList.filter(agent => agent?.lastName.includes(searchTerm));
    }
    case 'agency': {
      return agentsList.filter(agent => agent?.agency.includes(searchTerm));
    }
    case 'rating': {
      return agentsList.filter(agent => agent?.rating === Number(searchTerm));
    }
    case 'tags': {
      return agentsList.filter(agent => agent?.tags.some(tag => tag.includes(searchTerm)));
    }
    default: {
      return agentsList;
    }
  }
};

export const onFilter = (agentsList, filter) => {
  switch (filter) {
    case 'all': {
      return agentsList.filter(agent => agent?.doNotQuery === false)
    }
    case 'doNotQuery': {
      return agentsList.filter(agent => agent?.doNotQuery === true)
    }
    case 'openToQueries': {
      return agentsList.filter(agent => agent?.isOpen)
    }
    case 'closedToQueries': {
      return agentsList.filter(agent => agent?.isOpen === false)
    }
    default: {
      return agentsList;
    }
  }
};

export const onSort = (agentsList, sort) => {
  switch (sort) {
    case 'priority': {
      return sortByPriority(agentsList);
    }
    case 'alphabetical': {
      return sortByLastName(agentsList);
    }
    case 'alphabetical-agency': {
      return sortByAgencyName(agentsList);
    }
    case 'rating': {
      return sortByRating(agentsList);
    }
    default: {
      return agentsList
    }
  }
};