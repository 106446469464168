//==============================================================================
// External:
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

// Internal:
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import {
  useGetAgents,
  useGetQueries,
  useGetQueryTemplate,
  useGetUser
} from '../../hooks/commonHooks';
import QuestionDisplay from '../../components/QuestionDisplay/QuestionDisplay';
import { simpleUpdateQuery } from '../../store/actions/actions';
import { formatFinalPages } from '../../components/QueryFinalEmail/QueryFinalEmailUtils';
//==============================================================================

const QueryManagerSubmit = () => {
  const { queryId } = useParams();
  const user = useGetUser();
  const queryTemplate = useGetQueryTemplate()
  const query = useGetQueries()[queryId];
  const agent = useGetAgents()[query.agentId];
  const [isOpen, setIsOpen] = useState(false);
  const [queryManagerPersonalLink, setQueryManagerPersonalLink] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const copyStuff = async () => {
    // Get the text field
    const copyText = {
      querier: true,
      // referral: 'Chewbacca',
      authorBlog: queryTemplate.authorBlog,
      authorTwitter: queryTemplate.authorTwitter,
      authorWebsite: queryTemplate.authorWebsite,
      biography: queryTemplate.biography,
      bookPreviouslyPublished: queryTemplate.bookPreviouslyPublished,
      email: user.email,
      firstName: user.firstName,
      genre: queryTemplate.genre,
      hasBeenPreviouslyPublished: queryTemplate.hasBeenPreviouslyRepd,
      hasBeenPreviouslyRepd: queryTemplate.hasBeenPreviouslyRepd,
      lastName: user.lastName,
      phoneNumber: queryTemplate.phoneNumber,
      pitch: queryTemplate.pitch,
      queryLetter: query.finalEmailText,
      similarBooks: queryTemplate.similarBooks,
      samplePages: formatFinalPages('{{pages}}', agent, queryTemplate.chapterOneText, queryTemplate.chapterTwoText, queryTemplate.chapterThreeText),
      synopsis: queryTemplate.synopsis,
      targetAudience: queryTemplate.targetAudience,
      title: queryTemplate.title,
      wordCount: queryTemplate.wordCount,
    };

    // Copy the text inside the text field & open QT link:
    await navigator.clipboard.writeText(JSON.stringify(copyText));
    window.open(agent.queryManager, '_blank').focus();
    console.log("Copied the text: " + JSON.stringify(copyText));
  }

  const icon = isOpen ?
    <FontAwesomeIcon icon="fa-angle-down" />
    : <FontAwesomeIcon icon="fa-angle-up" />

  const onQTPersonalLinkSubmit = (event) => {
    event.preventDefault()
    dispatch(simpleUpdateQuery({
      queryId: query._id,
      queryManagerPersonalLink: queryManagerPersonalLink
    }));
    navigate(`/query-manager-review/${queryId}`)
  }

  return (
    <div className='QueryManagerSubmit__container'>
      <PageTitleBar
        title={'QueryManager'}
        subTitle={'Submit To QueryManager'}
      />
      <div className='QueryManagerSubmit__innerContainer'>
        <div className='QueryManagerSubmit__agentInfoContainer'>
          <div className='QueryManagerSubmit__agentInfoHeader'>Submitting Query For:</div>
          <div className='QueryManagerSubmit__agentInfoItem'>
            <div className='QueryManagerSubmit__agentInfoLabel'>Agent Name:</div>
            <div>{agent.firstName} {agent.lastName}</div>
          </div>
          <div className='QueryManagerSubmit__agentInfoItem'>
            <div className='QueryManagerSubmit__agentInfoLabel'>Agency:</div>
            <div>{agent.agency}</div>
          </div>
          <button
            className='QueryManagerSubmit__qtCopyButton'
            onClick={() => copyStuff()}
          >
            Open QueryManager
          </button>
          <div className='QueryManagerSubmit__submitQTLinkInputContainer'>
            <form onSubmit={(event) => onQTPersonalLinkSubmit(event)}>
              <div className='QueryManagerSubmit__formContainer'>
                <label className='QueryManagerSubmit__agentInfoLabel'>QueryManager Personal Link:</label>
                <label>
                  <input
                    value={queryManagerPersonalLink}
                    placeholder='Paste Your Email Link Here...'
                    onChange={(event) => setQueryManagerPersonalLink(event.target.value)}
                  />
                </label>
                <QuestionDisplay tooltipKey={'queryManagerPersonalLink'} />
              </div>
              <button className='QueryManagerSubmit__formButton'>Submit</button>
            </form>
          </div>
        </div>
        <div className='QueryManagerSubmit__directions'>
          <div onClick={() => setIsOpen(!isOpen)}>
            <span className='QueryManagerSubmit__directionsHeader'> How Do I Submit To QueryManager?</span>
            <span>{icon}</span>
          </div>
          {
            isOpen &&
            <div className='QueryManagerSubmit__text'>
              <p>To quickly and easily submit your query to QueryManager:</p>
              <ol type="1">
                <li>Add the <a href='https://google.com'>Querier Browser Extension</a> to your browser</li>
                <li>Click the 'Open QueryManager' button below</li>
                <li>Querier will open a new tab with the correct QueryManager form. Head over to this tab and open the Querier extension.</li>
                <li>Click the 'Submit to QueryManager' button and Querier will populate the form with your query information!</li>
                <li>Review the data, then click QueryManager's 'Submit Query' button at the bottom of the page</li>
              </ol>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default QueryManagerSubmit;
