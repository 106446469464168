//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Internal:
import { useGetProject } from '../../hooks/commonHooks';
import { getAgents } from '../../store/slices/agents.slice';
import { updateAgentPriority } from '../../store/actions/actions';
import { handleList } from './Agent.utils';
import { orderedAgentList } from '../../utils/utils';
//==============================================================================

export const useAgentsHooks = () => {
  const { projectId } = useParams();
  const { title: projectTitle } = useGetProject(projectId)
  const [orderHasChanged, setOrderHasChanged] = useState(false);
  const agentsObj = useSelector(getAgents);
  const [filter, setFilter] = useState('all');
  const [sort, setSort] = useState('priority');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCriteria, setSearchCriteria] = useState('none');
  const [shouldAllowDnD, setShouldAllowDND] = useState(true);
  const [agents, setAgents] = useState(orderedAgentList(agentsObj));

  useEffect(() => {
    setAgents(orderedAgentList(agentsObj))
  }, [])

  const dispatchSetPriority = ({ newOnDropList }) => {
    dispatch(updateAgentPriority({ newAgentList: newOnDropList, projectId, setOrderHasChanged }))
    setOrderHasChanged(false);
  }

  
  useEffect(() => {
    const agentsList = handleList({agentsObj, filter, searchTerm, searchCriteria, sort})
    setAgents(agentsList);
  }, [agentsObj, searchCriteria, searchTerm, sort, filter])


  useEffect(() => {
    if (searchCriteria !== 'none') {
      setSearchCriteria('none');
    }

    if (searchTerm) {
      setSearchTerm('');
    }

    if (filter === 'all' && sort === 'priority') {
      setShouldAllowDND(true);
    } else {
      setShouldAllowDND(false);
    }
  }, [filter, sort])


  useEffect(() => {
    // prevent DND when searching:
    if (searchTerm && sort === 'priority') {
      setShouldAllowDND(false)
    }
    if (!searchTerm && sort === 'priority' && filter === 'all') {
      setShouldAllowDND(true)
    }
  }, [searchTerm])

  const dispatch = useDispatch();

  return {
    agents,
    dispatchSetPriority,
    filter,
    orderHasChanged,
    projectId,
    projectTitle,
    searchCriteria,
    searchTerm,
    setAgents,
    setFilter,
    setSearchCriteria,
    setSearchTerm,
    setSort,
    searchTerm,
    shouldAllowDnD,
    sort,
  }
}
