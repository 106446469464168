//==============================================================================
// External:
import React from 'react';

// Internal:
import QuestionDisplay from '../../components/QuestionDisplay/QuestionDisplay';
import useQueryTitle from './QueryTitle.hooks';
import { updateQueryTemplate } from '../../store/actions/actions';
import { BASE_TEXT_EMAIL_SUBJECT } from '../../utils/constants';
// import { useNavigateAway } from '../../hooks/useNavigateAway';
//==============================================================================

const QueryTitle = ({ props }) => {
  const {
    dispatch,
    emailSubjectFS,
    projectId,
    setEmailSubjectFS,
    setTemplateTitleFS,
    templateTitleFS,
  } = props;

  // const contentHasBeenEntered = () => {
  //   if (templateTitleFS && emailSubjectFS !== BASE_TEXT_EMAIL_SUBJECT) return true;
  //   return false;
  // }

  const handleQuerySubmit = (event) => {
    event.preventDefault();
    dispatch(updateQueryTemplate({
      emailSubject: emailSubjectFS,
      projectId,
      title: templateTitleFS,
    }));
  }

  //  useNavigateAway(
  //   contentHasBeenEntered,
  //   updateQueryTemplate({
  //     emailSubject: emailSubjectFS,
  //     projectId,
  //     title: templateTitleFS,
  //   })
  // );

  return (
    <div className='QueryTitle__container'>
      <form
        className='QueryTitle__form'
        onSubmit={(event) => handleQuerySubmit(event)}
      >
        <div className='QueryTitle__queryTitle bold'>
          <label>Query Version Name:</label>
          <input
            id='QueryVersionNameInput'
            onChange={(event) => setTemplateTitleFS(event.target.value)}
            placeholder={'Enter query version name...'}
            value={templateTitleFS}
            />
          <QuestionDisplay tooltipKey={'queryBuilderTemplateTitle'} />
        </div>
        <div className='QueryTitle__emailSubject bold'>
          <label>Default Email Subject:</label>
          <input
            id='EmailSubjectInput'
            onChange={(event) => setEmailSubjectFS(event.target.value)}
            placeholder={emailSubjectFS}
            value={emailSubjectFS}
          />
          <QuestionDisplay tooltipKey={'queryBuilderEmailSubject'} />
        </div>
      </form>
    </div>
  )
}

export default QueryTitle;
