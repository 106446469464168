//==============================================================================
// External:
import React from 'react';
import deepEqual from 'deep-equal';

// Internal:
import AgentSelect from '../AgentSelect/AgentSelect';
import Editor from '../Editor/Editor';
import PageSubtitle from '../PageSubtitle/PageSubtitle';
import SubstitutionEntry from '../SubstitutionEntry/SubstitutionEntry';
import QueryFinalEmail from '../QueryFinalEmail/QueryFinalEmail';
import useQueryBuilderFormHooks from './QueryBuilderForm.hooks';
import { hasExistingGmailDraft, objHasData } from '../../utils/utils';
import {
  deleteQuery,
  finalizeQuery,
  gmailSendDraft,
  updateQuery
} from '../../store/actions/actions';
//==============================================================================

// see Note 1 for preselectedQuery & preselectedAgentId info
const QueryBuilderForm = ({ preselectedQuery = {}, preselectedAgentId = '' }) => {
  const {
    agent,
    agentId,
    agents,
    dispatch,
    emailSubjectFS,
    existingQuerySubstitutions,
    existingSubstitutionEntriesFS,
    finalEmail,
    finalEmailHTML,
    navigate,
    newQueryLetterText,
    newSubstitutionEntriesFS,
    projectId,
    queryBodyFS,
    queryFormat,
    queryTemplate,
    setAgentId,
    setEmailSubjectFS,
    setExistingSubstitutionEntriesFS,
    setQueryBodyFS,
    setNewQueryLetterText,
    setNewSubstitutionEntriesFS,
    substitutionTemplates,
  } = useQueryBuilderFormHooks(preselectedQuery, preselectedAgentId)

  const queryIsComplete = () => {
    if (!objHasData(agent)) return false;
    if (!emailSubjectFS) return false;
    if (!finalEmailHTML) return false;
    if (!allSubsHaveFinalText({ ...existingSubstitutionEntriesFS })) return false;
    return true;
  }

  const allSubsHaveFinalText = (substitutionEntries) => {
    return Object.values(substitutionEntries).every(subEntry => {
      return Boolean(subEntry.finalText);
    })
  }

  const formatPagesText = () => {
    if (!agent || Object.keys(agent).length === 0) return '';
    let appendedLtr = agent?.pagesType === 'Chapter' ? 's' : ''
    return `${agent?.pageCount} ${agent?.pagesType}${appendedLtr}`
  }

  const formatAgentRequirements = (agentRequirement) => {
    if (!agent || Object.keys(agent).length === 0) return '';
    return agent[agentRequirement] ? 'Required' : 'Not Required'
  }

  // update substitutionEntryFS w new text
  const handleSubstitutionEntriesInput = (substitutionEntry) => {
    if (Object.keys(existingQuerySubstitutions).includes(substitutionEntry.substitutionId)) {
      // handle changes to existing subs texts:
      setExistingSubstitutionEntriesFS({
        ...existingSubstitutionEntriesFS,
        [substitutionEntry.substitutionId]: { ...substitutionEntry } // use key of the substitutionTemplate as the key for the substitutionEntry
      })
    } else {
      // allow user to create new subs texts:
      const { _id, ...attrsToSave } = substitutionEntry;
      setNewSubstitutionEntriesFS({
        ...newSubstitutionEntriesFS,
        [substitutionEntry.substitutionId]: { ...attrsToSave } // use key of the substitutionTemplate as the key for the substitutionEntry
      })
    }
  }

  const getSubEntry = (substitution) => {
    if (!existingSubstitutionEntriesFS || Object?.keys(existingSubstitutionEntriesFS)?.length === 0) return {};
    // return local substitution entry value matching the substitution in template
    return Object?.values(existingSubstitutionEntriesFS)?.filter(subEnt => subEnt.substitutionId === substitution._id)[0]
  }

  const createSubstitutionList = Object.values(substitutionTemplates).map((substitution) => {
    return <SubstitutionEntry
      substitution={substitution}
      handleSubstitutionEntriesInput={handleSubstitutionEntriesInput}
      substitutionEntry={getSubEntry(substitution)}
    />
  });

  const handleTextInput = (input) => {
    setNewQueryLetterText(input); // update template with any changes made
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const hasUpdatedSubstritutionEntries =
      !deepEqual(existingSubstitutionEntriesFS, existingQuerySubstitutions)
      || Object.keys(newSubstitutionEntriesFS).length > 0;

    const props = {
      finalEmailText: finalEmail,
      emailSubject: emailSubjectFS,
      queryLetterText: newQueryLetterText,
      agentId,
      queryFormat,
      queryTemplateId: preselectedQuery?.queryTemplateId || queryTemplate._id,
      projectId: projectId || preselectedQuery.projectId,
      navigate,
      substitutionEntries: hasUpdatedSubstritutionEntries ? { existingSubstitutionEntriesFS, newSubstitutionEntriesFS } : {},
    };

    if (objHasData(preselectedQuery)) {
      dispatch(updateQuery({ ...props, queryId: preselectedQuery?._id }));
    } else {
      dispatch(finalizeQuery({ ...props }));
    }
  }

  const makeNumber = (number) => {
    return <div className={`QueryBuilderForm__number`}>{number}</div>
  }

  const onClear = () => {
    navigate(`/project/${projectId}/queries`);
  }

  const onDelete = () => {
    dispatch(deleteQuery({ ...preselectedQuery, navigate }));
  }

  const onSendGmailDraft = () => {
    dispatch(
      gmailSendDraft({
        agent,
        query: preselectedQuery,
        email: finalEmail,
        projectId: preselectedQuery.projectId,
        navigate
      })
    )
  }

  const sendToGmailButton = () => {
    if (!preselectedQuery._id) return;
    if (hasExistingGmailDraft(preselectedQuery)) return;
    if (!queryFormat) return;

    const button = queryFormat === 'Email' ?
      <button
        className='QueryBuilderForm__sendToGmail'
        disabled={!queryIsComplete()}
        onClick={() => onSendGmailDraft()}
        type='button'
      >
        Send Draft To Gmail
      </button>
      : <button
        className='QueryBuilderForm__sendToGmail'
        disabled={!queryIsComplete()}
        onClick={() => navigate(`/query-manager-submit/${preselectedQuery._id}`)}
        type='button'
      >
        Post To QueryManager
      </button>

    return (
      button
    )
  }

  return (
    <div className='QueryBuilderForm__container'>
      <form className='QueryBuilderForm__formContainer' onSubmit={(event) => handleSubmit(event)}>
        <div className='QueryBuilderForm__mainContainer'>
          <div className='QueryBuilderForm__agentSelectContainer'>
            <div>
              <div className='QueryBuilderForm__titleContainer'>
                {makeNumber('1')}
                <PageSubtitle title={'Select Agent To Query'} shouldShowQuestionMark tooltipKey='queryBuilderAgent' />
              </div>
              {
                preselectedQuery._id ?
                  <div>
                    <div><span>Selected Agent:</span></div> {`${agent.firstName} ${agent.lastName} | ${agent.agency}`}
                  </div>
                  : <AgentSelect
                    agentId={agentId}
                    agents={agents}
                    setAgent={setAgentId}
                  />
              }
            </div>
            <div className="QueryBuilderForm__agentRequirementsContainer">
              <div className='QueryBuilderForm__titleContainer'>
                {makeNumber('2')}
                <PageSubtitle title={'Agent Requirements'} shouldShowQuestionMark tooltipKey='queryBuilderAgentReqs' />
              </div>
              <div className="QueryBuilderForm__emailSubjectContainer">
                <span>Email Subject: </span>
                <input
                  className="QueryBuilderForm__inputField"
                  onChange={(event) => { setEmailSubjectFS(event.target.value) }}
                  type='text'
                  value={emailSubjectFS}
                />
              </div>
              <div>
                <span>Query Letter:</span> {formatAgentRequirements('queryLetterRequired')}
              </div>
              <div>
                <span>Synopsis:</span> {formatAgentRequirements('synopsisRequired')}
              </div>
              <div>
                <span>Writing Sample:</span> {formatPagesText()}
              </div>
              <div>
                <span>Query Type: </span> {queryFormat}
              </div>
            </div>
            <div className="QueryBuilderForm__substitutionsContainer">
              <div className='QueryBuilderForm__titleContainer'>
                {makeNumber('3')}
                <PageSubtitle title={'Text Substitutions'} shouldShowQuestionMark tooltipKey='queryBuilderSubstitutionEntries' />
              </div>
              <div>
                {createSubstitutionList}
              </div>
            </div>
          </div>
          <div className='QueryBuilderForm__Editor'>
            <div className='SubstitutionFinalText__editor'>
              <div className='QueryBuilderForm__titleContainer'>
                {makeNumber('4')}
                <PageSubtitle title={'Editor'} shouldShowQuestionMark tooltipKey='queryBuilderEditor' />
              </div>
              <div>
                <Editor setValue={handleTextInput} text={newQueryLetterText} />
              </div>
            </div>
          </div>
          <div className='QueryBuilderForm__finalEmail'>
            <QueryFinalEmail
              agentId={agentId}
              emailSubject={emailSubjectFS}
              finalEmailHTML={finalEmailHTML}
            />
          </div>
        </div>
        <div className='QueryBuilderForm__buttonContainer'>
          <button
            disabled={!agentId && !preselectedQuery._id}
          >
            Save Query
          </button>
          {
            sendToGmailButton()
          }
          <button
            type='button'
            onClick={() => preselectedQuery._id ? onDelete() : onClear()}
            className='QueryBuilderForm__deleteButton'
          >
            {preselectedQuery._id ? 'Delete Query' : 'Discard Query Changes'}
          </button>
        </div>
      </form>
    </div >
  )
}

export default QueryBuilderForm;

/*
NOTES:
1. 'preselectedQuery' is when clicking on main query from QueryBuilder page list. The 'preselectedAgent'
   is when clicking 'Query This Agent' on the agent page
*/

