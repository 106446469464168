//===================================================================================
// External
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Internal

//===================================================================================

const CheckListItem = ({
  component,
  hasCompletedStep,
  isLastItem = false,
  number,
  openByDeault = false,
  text,
  title
}) => {
  const [shouldOpen, setShouldOpen] = useState(openByDeault);

  const styles = {
    justifyContent: `${isLastItem ? `flex-start` : 'none'}`
  }

  const displayIcon = () => {
    return (shouldOpen)
      ? <FontAwesomeIcon icon="fa-angle-down" onClick={() => setShouldOpen(false)} />
      : <FontAwesomeIcon icon="fa-angle-up" onClick={() => setShouldOpen(true)} />
  };

  return (
    <div className="CheckListItem__container" >
      <div className={`CheckListItem__numberContainer`} style={styles}>
        <div className={`CheckListItem__number ${hasCompletedStep ? 'green' : 'red'}`}>
          {number}
        </div>
        {!isLastItem && <div className='CheckListItem__verticalLine'></div>}
      </div>
      <div className='CheckListItem__titleText'>
        <div className='CheckListItem__iconTitleContainer'>
          <div className='ChecklistItem__arrowIcon'>{displayIcon()}</div>
          <div className='CheckListItem__title' onClick={() => setShouldOpen(!shouldOpen)}>
            {title}
          </div>
        </div>
        {shouldOpen &&
          <>
            <div className='CheckListItem__text'>{text}</div>
            <div className='CheckListItem__component'>
              {component}
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default CheckListItem;