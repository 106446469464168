//===================================================================================
// External
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Internal
import BetaAnnouncement from '../BetaAnnouncement/BetaAnnouncement';
import { handleIdleSession } from '../../utils/utils';
//===================================================================================

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleIdleSession(dispatch, navigate);
  }, []);

  return (
    <div className="Dashboard__container">
      <BetaAnnouncement/>
    </div>
  )
}

export default Dashboard;
