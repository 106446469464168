//===================================================================================
// External
import parse from 'html-react-parser';

// Internal
import { checkSessionExpiration } from '../store/actions/actions';
import { APP_EXTEND_SESSION } from "./actionConstants";
import {
  BASE_TEXT_CH_ONE,
  BASE_TEXT_CH_TWO,
  BASE_TEXT_SYNOPSIS,
  BASE_TEXT_QUERY,
  BASE_TEXT_CH_THREE,
  BASE_TEXT_EMAIL_SUBJECT
} from './constants';
//===================================================================================

export const checkEqualValueOrUntruthy = (value1, value2) => {
  if (!value1 && !value2) return false;
  if (value1 === value2) return false;
  return true;
}

export const checkTagsEquality = (arr1, arr2) => {
  if ((!Boolean(arr1) || arr1.length === 0) && (!Boolean(arr2) || arr2.length === 0)) return false;
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  const areEqual = arr1?.every(item => set2.has(item)) &&
    arr2?.every(item => set1.has(item))
  return areEqual ? false : true;
}

export const contentHasChanged = (localState, reduxState) => {
  return localState !== reduxState;
}

export const convertTextToHTML = (formattedText) => {
  if (!formattedText) return;
  return parse(formattedText);
}

export const finalEmailFormat = ({ emailAddress, emailSubject, emailBody }) => {
  return (
    <>
      <div>
        To: {emailAddress}
      </div>
      <div>
        Subject: {emailSubject}
      </div>
      <div className='QueryFinalReview__finalEmail'>
        {emailBody}
      </div>
    </>
  )
}

export const formateDate = (date) => {
  const d = new Date(date);
  return `${d.getMonth()}/${d.getDate()}/${d.getFullYear()}`
}

export const makeInput = (onChangeHandler, value, customClassName = '', placeholder = '', miscAttrs = null) => {
  return (
    <input
      className={`AgentForm__input ${customClassName}`}
      onChange={(event) => onChangeHandler(event.target.value)}
      placeholder={placeholder}
      value={value}
      {...(miscAttrs && { ...miscAttrs })}
    />
  )
}

export const makeCheckbox = (onChangeHandler, value, customClassName, baseClass) => {
  return (
    <input
      className={`AgentForm__​input ${customClassName}`}
      type="checkbox"
      checked={value}
      onChange={() => onChangeHandler(!value)}
    />
  )
}

export const makeUUID = () => {
  return 'xxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

export const hasAddedAgents = (agentList) => Object.keys(agentList).length > 0;

export const hasAddedChapters = (chapterOneText, chapterTwoText, chapterThreeText) => {
  const hasAddedCh1 = chapterOneText !== BASE_TEXT_CH_ONE;
  const hasAddedCh2 = chapterTwoText !== BASE_TEXT_CH_TWO;
  const hasAddedCh3 = chapterThreeText !== BASE_TEXT_CH_THREE;
  return hasAddedCh1 && hasAddedCh2 && hasAddedCh3;
}

export const hasChangedQueryMgrInfo = (queryTemplateProps) => {
  const { originalQueryTemplate: original, ...newQM } = queryTemplateProps;
  if (original.authorBlog !== newQM.authorBlog) return true;
  if (original.authorTwitter !== newQM.authorTwitter) return true;
  if (original.authorWebsite !== newQM.authorWebsite) return true;
  if (original.biography !== newQM.biography) return true;
  if (original.bookPreviouslyPublished !== newQM.bookPreviouslyPublished) return true;
  if (original.bookTitle !== newQM.bookTitle) return true;
  if (original.genre !== newQM.genre) return true;
  if (original.hasBeenPreviouslyPublished !== newQM.hasBeenPreviouslyPublished) return true;
  if (original.hasBeenPreviouslyRepd !== newQM.hasBeenPreviouslyRepd) return true;
  if (original.phoneNumber !== newQM.phoneNumber) return true;
  if (original.pitch !== newQM.pitch) return true;
  if (original.referral !== newQM.referral) return true;
  if (original.similarBooks !== newQM.similarBooks) return true;
  if (original.targetAudience !== newQM.targetAudience) return true;
  if (original.wordCout !== newQM.wordCout) return true;
  return false;
}

export const hasCompletedQueryMgrInfo = (queryTemplateProps) => {
  const { originalQueryTemplate: original, ...newQM } = queryTemplateProps;
  if (
    newQM.authorBlog
    && newQM.authorTwitter
    && newQM.authorWebsite
    && newQM.biography
    && (newQM.bookPreviouslyPublished === true || newQM.bookPreviouslyPublished === false)
    && newQM.bookTitle
    && newQM.genre
    && (newQM.hasBeenPreviouslyPublished === true || newQM.hasBeenPreviouslyPublished === false)
    && (newQM.hasBeenPreviouslyRepd === true || newQM.hasBeenPreviouslyRepd === false)
    && newQM.phoneNumber
    && newQM.pitch
    // && newQM.referral
    && newQM.similarBooks
    && newQM.targetAudience
    && newQM.wordCount) {
    return true
  } else {
    return false;
  }
}

export const hasAddedEmailSubject = (emailSubject) => (emailSubject !== BASE_TEXT_EMAIL_SUBJECT) || emailSubject === '';

export const hasAddedQueryText = (queryText) => queryText !== BASE_TEXT_QUERY;

export const hasAddedSubstitutions = (substitutions) => Object.keys(substitutions).length > 1;

export const hasAddedSynopsis = (synopsis) => synopsis !== BASE_TEXT_SYNOPSIS || synopsis === '';

export const hasAddedQueryTitle = (title) => {
  if (title) return true;
  return false;
}

export const handleIdleSession = (dispatch, navigate) => {
  let HAS_STARTED_COUNTDOWN = false;
  const timeoutInMiliseconds = 2 * 60 * 1000;
  let timeoutId;

  function startTimer() {
    timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds);
  }

  function resetTimer() {
    // fire 'cancel' action to kill timers in saga (if saga has been called)
    window.clearTimeout(timeoutId);
    startTimer();
    if (HAS_STARTED_COUNTDOWN) {
      dispatch({ type: APP_EXTEND_SESSION })
      HAS_STARTED_COUNTDOWN = false;
    }
  }

  function doInactive() {
    console.log("---------- CLIENT INACTIVE ---------");
    dispatch(checkSessionExpiration({ navigate, resetTimer }));
    HAS_STARTED_COUNTDOWN = true;
  }

  function setupTimers() {
    document.addEventListener("onload", resetTimer, false);
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("onclick", resetTimer, false);
    document.addEventListener("keydown", resetTimer, false);
    document.addEventListener("scroll", resetTimer, false);

    startTimer();
  }

  setupTimers();
};

export const makeObjFromArray = (elements) => {
  return elements?.reduce((accumulator, element) => {
    accumulator[element._id] = element;
    return accumulator;
  }, {});
}

export const objHasData = (obj) => Object.keys(obj).length > 0

export const hasExistingGmailDraft = (query) => {
  return query.gmailDraftId && query.dateSentToInbox;
};

export const orderedAgentList = (agents) => Object.values(agents)
  .filter(agent => !agent.doNotQuery)
  .sort((agent1, agent2) => agent1.priority - agent2.priority);

export const rowColor = (number) => {
  return number % 2 === 0 ? '' : 'lightgrey';
}

export const saveVehiclesByVin = (vehicles) => {
  vehicles.reduce((accumulator, vehicle) => {
    accumulator[vehicle.vin] = { ...vehicle }
    return accumulator
  })
};

export const setIsOpenValue = (agent) => {
  if ((agent?.hasOwnProperty('isOpen'))) {
    return agent?.isOpen
  }
  return true;
}

export const sortByAgencyName = (agentsList) => {
  return Object.values(agentsList).sort(function (a, b) {
    if (a.agency < b.agency) { return -1; }
    if (a.agency > b.agency) { return 1; }
    return 0;
  })
}

export const sortByLastName = (agentsList) => {
  return Object.values(agentsList).sort(function (a, b) {
    if (a.lastName < b.lastName) { return -1; }
    if (a.lastName > b.lastName) { return 1; }
    return 0;
  })
}

export const sortByPriority = (agentsList) => Object.values(agentsList).sort((agent1, agent2) => agent1.priority - agent2.priority);

export const sortByRating = (agentsList) => Object.values(agentsList).sort((agent1, agent2) => agent2.rating - agent1.rating)

export const tooltipStyles = {
  fontSize: '12px',
  maxWidth: '30%',
  whiteSpace: 'pre-wrap'
}

export const timeDiffInDays = (date) => {
  const newDate = new Date(date);
  const today = Date.now();
  const diffInMillis = today - newDate.getTime();
  const days = (diffInMillis / (60 * 60 * 24 * 1000))
  return Math.round(days);
}
