//==============================================================================
// External:
import React from 'react';

// Internal:
import useQueryFinalReviewHooks from '../QueryFinalReview/QueryFinalReviewHooks';
//==============================================================================

const QueryManagerReview = () => {
  const { query } = useQueryFinalReviewHooks();

  const onOpenQueryManager = () => {
    window.open(query.queryManagerPersonalLink, '_blank').focus();
  }

  return (
    <div className='QueryFinalReview__container'>
      <button onClick={() => onOpenQueryManager()}>Open QueryManager</button>
    </div>
  )
}

export default QueryManagerReview;
