//===================================================================================
// External
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Internal
import PageTitleBar from '../PageTitleBar/PageTitleBar';
import { useGetProject } from '../../hooks/commonHooks';
import QueryBuilderForm from '../QueryBuilderForm/QueryBuilderForm';
//===================================================================================

// TODO: moves this to 'views'
const QueryCreate = () => {
  // used when clicking 'Query This Agent'
  const { state } = useLocation();
  let agentId;
  if (state) {
    ({agentId} = state);
  }

  // used when clicking 'Create Query'
  const { projectId } = useParams();
  const { title: projectTitle } = useGetProject(projectId);

  return (
    <div className="QueryCreate__container" >
      <PageTitleBar
        title={projectTitle}
        subTitle={'New Query'}
      />
      <div className="QueryCreate__contentContainer">
        <QueryBuilderForm preselectedAgentId={agentId}/>
      </div>
    </div>
  )
}

export default QueryCreate;