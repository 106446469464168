//==============================================================================
/* External */
import {createSlice} from '@reduxjs/toolkit';
//==============================================================================

const initialState = {
  flashMessage: {},
  isLoading: false,
  modalContent: '',
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setFlashMessage(state, action) {
      state.flashMessage = action.payload
    },
    setLoading(state) {
      state.isLoading = !state.isLoading
    },
    setModalContent(state, action) {
      state.modalContent = action.payload
    }
  },
});

// selectors:
export const getFlashMessage = (state) => state.ui.flashMessage;
export const getLoading = (state) => state?.ui?.isLoading;
export const getShowModal = (state) => state.ui.modalContent;

// actions:
export const {
  setFlashMessage,
  setLoading,
  setModalContent
} = uiSlice.actions;

// reducer:
export default uiSlice.reducer;
