//==============================================================================
// External:
import React from 'react';

// Internal:
import StatusComponent from '../StatusComponent/StatusComponent';
import { useGetQueries } from '../../../../hooks/commonHooks';
//==============================================================================

const QueriesStatus = ({ }) => {
  const queries = useGetQueries();
  const numberOfQueries = Object.keys(queries).length;
  const queriesSent = Object.values(queries).filter(query => query.dateSentToAgent).length;
  const queriesInWaitingStatus = Object.values(queries).filter(query => query.status === 'waiting').length;
  const queriesRejected = Object.values(queries).filter(query => query.status === 'rejection-form' || query.status === 'rejection-personal').length
  const queriesRejectedPercent = () => {
    if (queriesRejected) {
      return `${queriesRejected/queries}%`
    }
    return '0'
  }

  return (
    <div className='QueryStatus__container'>
      <StatusComponent
        title={"Queries Created"}
        metric={numberOfQueries}
        icon='fa-envelope'
        />
      <StatusComponent
        title={"Queries Sent"}
        metric={queriesSent}
        icon='fa-paper-plane'
      />
      <StatusComponent
        title={"Queries Awaiting Response"}
        metric={queriesInWaitingStatus}
        icon='fa-hourglass-half'
      />
      <StatusComponent
        title={"Queries Rejected"}
        metric={queriesRejectedPercent()}
        icon='fa-ban'
      />
    </div>
  )
}

export default QueriesStatus;

// - number of queries
// - status
// - number of responses
