//==============================================================================
// External:

// Internal:
import { convertTextToHTML } from "../../utils/utils";
//==============================================================================

const formatFinalPages = (finalEmail, agent, chapterOneText, chapterTwoText, chapterThreeText) => {
  let { pageCount, pagesType } = agent || {};

  if (pagesType === 'Words') {
    const words = `${chapterOneText}${chapterTwoText}${chapterThreeText}`
    const finalWords = getFinalWords(words, pageCount)
    finalEmail = finalEmail.replace('{{pages}}', finalWords);
  }

  if (pagesType === 'Pages') {
    const pages = `${chapterOneText}${chapterTwoText}${chapterThreeText}`
    const finalPages = getFinalWords(pages, pageCount * 250)
    finalEmail = finalEmail.replace('{{pages}}', finalPages);
  }

  if (pagesType === 'Chapter') {
    if (pageCount === 1) {
      finalEmail = finalEmail.replace('{{pages}}', chapterOneText);
    }

    if (pageCount === 2) {
      finalEmail = finalEmail.replace('{{pages}}', `${chapterOneText} ${chapterTwoText}`);
    }

    if (pageCount === 3) {
      finalEmail = finalEmail.replace('{{pages}}', `${chapterOneText} ${chapterTwoText} ${chapterThreeText}`);
    }
  }

  return finalEmail
}

const hasPuctionation = (substring) => {
  return substring.includes('.') || substring.includes('!') || substring.includes('?')
}

const getFinalWords = (words, pageCount) => {
  const textWithoutHTMLTags = words.replace(/(<([^>]+)>)/ig, ' '); // remove all HTML tags
  const wordsArray = textWithoutHTMLTags.split(" ").filter(word => word.trim()); // remove all empty words
  const arrayOfPageCountLength = wordsArray.slice(0, pageCount); // new array w all remaining words (not tags, empty spaces)
  const reversedArray =  arrayOfPageCountLength.toReversed();
  const lastWordWithPunctuation = reversedArray.find(substring => hasPuctionation(substring)) // locating end of last sentence
  const endingIndex = reversedArray.findIndex(substring => substring === lastWordWithPunctuation); // get index of final word
  const arrayOfRoundedText = arrayOfPageCountLength.slice(0, pageCount - endingIndex); // get array of included text
  const finalPhrase =  arrayOfRoundedText.at(-1) // get final phrase to end text at
  const finalPhraseTextIndex = words.indexOf(finalPhrase)  // get text up to final phrase
  const formattedWords = words.slice(0, finalPhraseTextIndex + finalPhrase.length)  //  FINAL INDEX = finalPhrase.length

  return formattedWords;
}

const formatFinalEmail = ({ agent, queryTemplate, newQueryLetterText, substitutionTemplates, substitutionEntriesFS }) => {
  const {
    emailTemplate,
    synopsis,
    chapterOneText,
    chapterTwoText,
    chapterThreeText
  } = queryTemplate;

  const {queryManager} = agent;

console.log('FORMAT FINAL EMAIL', {queryManager, queryTemplate, agent});

  let finalEmail = '';
  finalEmail = emailTemplate;

  if (agent?.queryLetterRequired && newQueryLetterText) {
    finalEmail = finalEmail.replace('{{queryLetter}}', newQueryLetterText);
  } else {
    finalEmail = finalEmail.replace('{{queryLetter}}', '');
  }

  if (agent?.synopsisRequired && synopsis && !queryManager) {
    finalEmail = finalEmail.replace('{{synopsis}}', synopsis)
  } else {
    finalEmail = finalEmail.replace('{{synopsis}}', '');
    finalEmail = finalEmail.replace('Synopsis:', '');
  }

  if (agent?.pagesType && agent?.pageCount && !queryManager) {
    finalEmail = formatFinalPages(finalEmail, agent, chapterOneText, chapterTwoText, chapterThreeText)
  } else {
    finalEmail = finalEmail.replace('{{pages}}', '');
    finalEmail = finalEmail.replace('Writing Sample:', '');
  }

  // don't loop if there are no substitution entries:
  if (Object.keys(substitutionEntriesFS).length > 0) {
    // Loop through substitutionTemplates and find matching substitutionEntryFS (have same keys)
    Object.values(substitutionTemplates).forEach((substitutionTemplate) => {
      // Replace the substitutionTemplate text with the final input text from substitutionEntriesFS in the finalEmail
      const userHasEnteredSignifier = Boolean(substitutionTemplate.signifier);
      const userHasSubstitutedText = substitutionEntriesFS.hasOwnProperty(substitutionTemplate._id)
      if (userHasEnteredSignifier && userHasSubstitutedText) {
        const replacementText = substitutionEntriesFS[substitutionTemplate._id].finalText;
        finalEmail = finalEmail.replace(substitutionTemplate.signifier, replacementText)
      }
    })
  }

  return { htmlVersion: convertTextToHTML(finalEmail), stringVersion: finalEmail };
}

const roundIndex = (text, pageCount) => {
  const array = text.split(' ').slice(0, pageCount); // pageCount is one greater, but .slice not inclusize

  // check if chosen item has <p></p>
  if (array.at(-1).includes('</p>')) {
    return pageCount;
  }

  // if not, determine if rounding up or down:
  const roundUpArray = text.split(' ').slice(pageCount); //  array from where 'array' left off to end of text
  const roundedUpIndex = roundUpArray.findIndex(word => word.includes('</p>')) + 1;  // find first '</p>', +1 bc zero index
  const roundedDownArray = text.split(' ').slice(0, pageCount - 1) // get array from where 'array' left off back toward beginning
  const roundedDownIndex = roundedDownArray.reverse().findIndex(word => word.includes('<p>')) + 1; // find first '<p>', + 1 bc zero index

  let finalIndex;
  if (roundedUpIndex <= roundedDownIndex) {
    finalIndex = Number(pageCount + roundedUpIndex)
  } else {
    finalIndex = Number(pageCount - roundedDownIndex)
  }

  return finalIndex;
}

const makeFinalArray = (text, index) => {
  const wordsArray = text.split(' ').slice(0, index);
  // remove empty spaces
  const arrayWithoutSpaces = wordsArray.filter(word => word.length > 0)
  let shortenedArray = arrayWithoutSpaces.slice(0, index); // get array of words up until cutoff
  const sanitizedItem = shortenedArray.at(-1).split('<p>')[0]; // remove </p>
  shortenedArray[shortenedArray.length - 1] = sanitizedItem; // replace last item in array
  return shortenedArray;
}

export {
  getFinalWords,
  formatFinalEmail,
  formatFinalPages,
  roundIndex
}